<template>

  <div class="pm-container mt-4">
    <router-link class="btn btn-primary" to="/mediathek">Zurück</router-link>
    <hr>

    <div class="row mb-3">
      <label for="inputTitle" class="col-sm-2 col-form-label">Videotitel</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="inputTitle" v-model="video.title">
      </div>
    </div>
    <div class="row mb-3">
      <label for="inputDescription" class="col-sm-2 col-form-label">Beschreibung</label>
      <div class="col-sm-10">
          <textarea class="form-control" rows="5" id="inputDescription" v-model="video.description">
          </textarea>
      </div>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">Neue Videodatei hochladen</label>
      <div class="col-sm-10">
        <FileUpload @uploadFinished="videoUploadFinished"></FileUpload>
      </div>

      <vue-plyr style="height: 400px" controls crossorigin>
        <video v-if="video.videoBlobId" class="card-img" playsinline controls :data-poster="thumbnailLinkPreview">
          <source :src="videoLinkPreview" type="video/mp4"/>
        </video>
      </vue-plyr>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">Neues Vorschaubild hochladen</label>
      <div class="col-sm-10">
        <FileUpload @uploadFinished="thumbnailUploadFinished"></FileUpload>
        <img v-if="video.thumbnailBlobId" class="mt-2" height="200" :src="thumbnailLinkPreview">
      </div>
    </div>

    <div class="row mb-3">
      <label for="videoLength" class="col-sm-2 col-form-label">Videolänge (in Minuten)</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="videoLength" v-model="video.lengthMin">
      </div>
    </div>

    <div class="btn-group" role="group" aria-label="Basic example">
      <button @click="updateVideo" class="btn btn-primary">Speichern</button>
      <button @click="deleteVideo" class="btn btn-danger">Löschen</button>
    </div>
  </div>
</template>

<script>
import FileUpload from "../../components/FileUpload";

export default {

  data() {
    return {
      video: {}
    }
  },

  mounted() {
    this.$store.dispatch("videos/edit", {token: this.$route.params.video}).then(() => {
      this.video = JSON.parse(JSON.stringify(this.$store.state.videos.editedVideo));
    })
  },

  components: {FileUpload},

  methods: {
    videoUploadFinished(file) {
      this.video.videoBlobId = file.fileId
    },

    thumbnailUploadFinished(file) {
      this.video.thumbnailBlobId  = file.fileId
    },

    deleteVideo() {
      this.$store.dispatch("videos/remove", {token: this.video.token}).then(() => {
        this.$router.push("/mediathek")
      });
    },

    updateVideo() {
      this.$store.dispatch("videos/update", this.video).then(() => {
        this.$router.push("/mediathek#" + this.video.token)
      });
    }
  },

  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },

    thumbnailLinkPreview() {
      if (!this.video.thumbnailBlobId)
        return null;

      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.video.thumbnailBlobId);
    },

    videoLinkPreview() {
      if (!this.video.videoBlobId)
        return null;

      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.video.videoBlobId);
    },
  },

  name: "VideoEdit"
}
</script>

<style scoped>

</style>